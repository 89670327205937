import React, { lazy } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import {ToastContainer} from "./utils/toast";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import PrivateRoute from './components/login/PrivateRoute';

const Layout = lazy(() => import('./layout/Layout'));
const SignIn = lazy(() => import('./pages/signIn'));
const ForgetPassword = lazy(() => import('./pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));

function App() {
  return (
      <>
        <ToastContainer />
        <Router>
          <AccessibleNavigationAnnouncer />
          <Switch>
            <Route path="/signin" component={SignIn} />
            <Route path="/forgot-password" component={ForgetPassword} />
            <Route path="/reset-password/:token" component={ResetPassword} />

            <PrivateRoute>
              {' '}
              <Route path="/" component={Layout} />
            </PrivateRoute>
            <Redirect exact from="/" to="/signin" />
          </Switch>
        </Router>
      </>
  );
}

export default App;
