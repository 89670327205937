export const translationEn = {
  // Authentification
  sign_in_connexion: "Login",
  sign_in_connexion_desc: "Please enter your credentials",
  sign_up: "Register",
  sign_up_desc: "Please fill in your personal information",
  sign_in_email: "E-mail address",
  sign_in_password: "Password",
  sign_in_password_confirm: "Confirm Password",
  sign_in_forgot_password: "Forgot your password ?",
  sign_in_forgot_password_desc: "Enter your Email to get a password reset link",
  forgot_password_connect: "Do you remember your password ?",
  sign_in_login_btn: "Sign in",
  sign_in_no_account: "Don't have an account ?",
  sign_up_no_account: "Already have an account ?",
  sign_in_register: "Sign up",
  sign_up_connect: "Sign in",

  // Side bar name
  DashboardOverview: "Dashboard",
  Car: "Car",
  Users: "Users",
  Clients: "Clients",
  Subscribers: "Subscribers",
  Employee: "Employees",
  Absences: "Absences",
  AbsenceRequests: "Absence requests",
  AbsenceManagement: "Absences managements",
  TimeTracking: "Time tracking",
  Estimated: "Estimated",
  EstimatedDetail: "Estimated Detail",
  EstimatedResponse: "Respond to Estimate",
  Bills: "Bills",
  StoreSetting: "Settings",
  ShiftWorkDetail: "Shift Work Details",

  // Dashboard
  DashboardClient: "Clients",
  DashboardQuarterTime: "Quarter time",
  DashboardEmployee: "Employees",
  DashboardSalary: "Total salary",

  // Users
  ClientNew: "New client",

  // Employee
  EmployeeNew: "New Employee",

  // Prospect
  ProspectNew: "New prospect",

  // Form
  FormFirstname: "First name",
  FormLastname: "Last name",
  FormEmail: "Email",
  FormSBusinessName: "Business structure",
  FormCompanyName: "Company name",
  FormTelephone: "Phone number",
};

//////////////////////////// French //////////////////////////////////////////////////////////////////////////////////////////////////////////

export const translationFr = {
  // Authentification
  sign_in_connexion: "connexion",
  sign_in_connexion_desc: "Veuillez entrer vos identifiants",
  sign_up: "S'enregistrer",
  sign_up_desc: "Veuillez remplir vos informations personnelles",
  sign_in_email: "Adresse Email",
  sign_in_password: "Mot de passe",
  sign_in_password_confirm: "Confirmez le Mot de passe",
  sign_in_forgot_password: "Mot de passe oublié ?",
  sign_in_forgot_password_desc:
    "Entrez votre Email pour obtenir un lien de réinitialisation du mot de passe",
  forgot_password_connect: "Vous vous souvenez de votre mot de passe ?",
  sign_in_login_btn: "Se Connecter",
  sign_in_no_account: "Pas encore de compte ?",
  sign_up_no_account: "Avez-vous déjà un compte ?",
  sign_in_register: "S'inscrire",
  sign_up_connect: "Se Connecter",

  // Side bar name
  DashboardOverview: "Tableau de bord",
  Car: "Véhicule",
  Users: "Utilisateurs",
  Clients: "Clients",
  Subscribers: "Abonnés",
  Employee: "Employés",
  Absences: "Absences",
  AbsenceRequests: "Demandes d’absences",
  AbsenceManagement: "Gestion d’absences",
  TimeTracking: "Suivi d’heures",
  Estimated: "Estimés",
  EstimatedDetail: "Details Estimés",
  EstimatedResponse: "Répondre à l'estimé",
  Bills: "Factures",
  StoreSetting: "Paramètres",
  ShiftWorkDetail: "Details du Quart de travail",

  // Dashboard
  DashboardClient: "Clients",
  DashboardQuarterTime: "Quart temps",
  DashboardEmployee: "Employés",
  DashboardSalary: "Salaire Total",

  // Users
  ClientNew: "Nouveau Users",

  // Employé
  EmployeeNew: "Nouvel Employé",

  // Prospect
  ProspectNew: "Nouveau prospect",

  // Form
  FormFirstname: "Prénom",
  FormLastname: "Nom",
  FormEmail: "Adresse courriel",
  FormSBusinessName: "Raison Sociale",
  FormCompanyName: "Nom de l’entreprise",
  FormTelephone: "Téléphone",
};
